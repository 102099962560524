import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { HeaderMenuFieldsFragment, HeaderQuery } from "../../../../graphql-types"
import * as config from "../../../../config.js"
import * as styles from "./header.module.scss"
import { Turn as Hamburger } from "hamburger-react"
import { useIsMobile } from "../../../hooks"
import SideMenu from "./side-menu/side-menu"
import DropdownNavbar, { KEY_CODES } from "./dropdown-menu-bar/dropdown-menu-bar"
import bell from "../../../../static/images/bell.svg"
import { ReactSVG } from "react-svg"
import LanguageSelector, { RelativeLanguagePaths } from "./header-tool-bar/language-selector/language-selector"
import { detectWebView } from "../../../utils"
import { useLinesSubscription } from "../../../hooks/use-lines-subscription"

type RenderProps = {
  menuData: HeaderMenuFieldsFragment
  locale: string
  defaultLocale: string
  relativeLanguagePaths: RelativeLanguagePaths
}

const Header: React.FC<RenderProps> = ({ menuData, locale, defaultLocale, relativeLanguagePaths }) => {
  const isMobile = useIsMobile(1000)
  const [isOpen, setOpen] = useState(false)
  const { isMySubscriptionsPageEnabled } = useLinesSubscription()

  const data: HeaderQuery = useStaticQuery(graphql`
    query Header {
      datoCmsGlobalConfiguration {
        primaryColor {
          hex
        }
        subsidiaryLogo {
          url
          alt
          width
          height
        }
      }
    }
  `)

  const closeMenu = () => setOpen(false)

  const handleKeyEvents = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.keyCode === KEY_CODES.SPACE) {
      event.preventDefault()
      event.stopPropagation()
      setOpen(!isOpen)
    }
  }

  useEffect(() => {
    if (document) {
      if (document.getElementsByClassName("hamburger-react") && document.getElementsByClassName("hamburger-react")[0]) {
        document.getElementsByClassName("hamburger-react")[0].setAttribute("aria-expanded", isOpen.toString())
      }
    }
  }, [isOpen])

  return (
    <div className={styles.globalHeader}>
      <div className={styles.header}>
        {isMobile && (
          <nav role="navigation" onKeyDown={event => handleKeyEvents(event)}>
            <Hamburger
              label="Menu"
              hideOutline={false}
              color={"white"}
              size={40}
              toggled={isOpen}
              toggle={setOpen}
              distance="md"
              aria-expanded={isOpen}
              rounded
            />
          </nav>
        )}
        <a href={locale === defaultLocale ? "/" : `/${locale}`} className={styles.subsidiaryLogo}>
          <img
            src={data.datoCmsGlobalConfiguration.subsidiaryLogo.url}
            alt={config.back_to_home_title[config.locale]}
            width={data.datoCmsGlobalConfiguration.subsidiaryLogo.width}
            height={data.datoCmsGlobalConfiguration.subsidiaryLogo.height}
          />
        </a>
        {isMobile ? (
          <>
            <SideMenu
              isOpen={isOpen}
              closeMenu={closeMenu}
              headerMenu={menuData}
              locale={locale}
              defaultLocale={defaultLocale}
            />
            {isMySubscriptionsPageEnabled && (
              <a
                href={locale === defaultLocale ? "/my-subscriptions/" : `/${locale}/my-subscriptions/`}
                className={styles.myAlert}
              >
                <ReactSVG src={bell} className={`${styles.bell} `} />
              </a>
            )}
          </>
        ) : (
          <DropdownNavbar headerMenu={menuData} locale={locale} defaultLocale={defaultLocale} />
        )}
      </div>
      {!isMobile && <LanguageSelector locale={locale} relativeLanguagePaths={relativeLanguagePaths} />}
      {!isMobile && isMySubscriptionsPageEnabled && (
        <a
          href={locale === defaultLocale ? "/my-subscriptions/" : `/${locale}/my-subscriptions/`}
          className={styles.myAlert}
        >
          <ReactSVG src={bell} className={`${styles.bell} `} />
        </a>
      )}
    </div>
  )
}

export const enableDidomi = () => {
  const head = document.getElementsByTagName("head")
  const script = document.createElement("script")

  script.type = "text/javascript"
  script.text =
    `window.gdprAppliesGlobally=true;(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}\n` +
    `else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}\n` +
    `var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}\n` +
    `if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e){\n` +
    `var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("${config.didomi_api_key}")})();`

  head[0].prepend(script)
}

export const enableGoogleAnalytics = () => {
  return (
    <script
      type="didomi/javascript"
      data-vendor="c:googleana-4TXnJigR"
      dangerouslySetInnerHTML={{
        __html: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
            ga('create', '${config.google_analytics_key}', 'auto');
            ga('send', 'pageview');`,
      }}
    />
  )
}

export const enableGoogleTagManagerHead = () => {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${config.google_tag_manager_id}');`,
      }}
    />
  )
}

export const enableGoogleTagManagerBody = () => {
  const body = document.getElementsByTagName("body")
  const noscript = document.createElement("noscript")
  const iframe = document.createElement("iframe")

  iframe.src = `https://www.googletagmanager.com/ns.html?id=${config.google_tag_manager_id}`
  iframe.height = "0"
  iframe.width = "0"
  iframe.setAttribute("style", "display:none;visibility:hidden")

  noscript.appendChild(iframe)

  body[0].prepend(noscript)
}

export const queryFragments = graphql`
  fragment HeaderMenuFields on DatoCmsMenu {
    id
    label
    headerDisplay1
    menuBypassLink
    mainContentBypassLink
    primaryColor {
      hex
    }
    gradientPrimaryColor {
      hex
    }
    svgColor {
      hex
    }
    menuSection {
      label
      externalPage
      page {
        slug
      }
      headerDisplay
      footerDisplay
      subMenu {
        label
        externalPage
        page {
          slug
        }
        headerDisplay
        footerDisplay
      }
    }
    externalPage
    page {
      slug
    }
    column1 {
      id
      label
      headerDisplay
      externalPage
      page {
        slug
      }
    }
    label2
    headerDisplay2
    externalPage2
    page2 {
      slug
    }
    column2 {
      id
      label
      headerDisplay
      externalPage
      page {
        slug
      }
    }
    label3
    headerDisplay3
    externalPage3
    page3 {
      slug
    }
    column3 {
      id
      label
      headerDisplay
      externalPage
      page {
        slug
      }
    }
    label4
    headerDisplay4
    externalPage4
    page4 {
      slug
    }
    column4 {
      id
      label
      headerDisplay
      externalPage
      page {
        slug
      }
    }
    label5
    headerDisplay5
    externalPage5
    page5 {
      slug
    }
    column5 {
      id
      label
      headerDisplay
      externalPage
      page {
        slug
      }
    }
  }
`

export default Header
