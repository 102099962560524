import { Script, graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import sanitizeHtml from "sanitize-html"
import { StringParam, useQueryParam } from "use-query-params"
import * as config from "../../../config"
import { SearchResultsQuery } from "../../../graphql-types"
import { RelativeLanguagePaths } from "../../components/layout/header/header-tool-bar/language-selector/language-selector"
import Layout from "../../components/layout/layout"
import LoadScripts from "../../components/load-scripts"
import SearchForm from "../../components/search/search-form"
import SEO from "../../components/seo"
import TitleBanner from "../../components/type-page/title-banner/title-banner"
import { pushGtmEvent, pushGtmPageEvent } from "../../gtm/interaction-event"
import { useDetectScroll, useFormatLocale } from "../../hooks"
import * as styles from "./search-results.module.scss"

enum SearchState {
  NoSearch,
  Searching,
  Success,
  Error,
}

type RenderProps = {
  data: SearchResultsQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    relativeLanguagePaths: RelativeLanguagePaths
  }
}

const SearchResults: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const siteSearchUrl = "https://unpkg.com/datocms-search@0.1.8/dist/datocms-search.base.js"

  const [siteSearchLoaded, setSiteSearchLoaded] = useState(typeof window !== "undefined" && !!window["DatoCmsSearch"])
  const [client, setClient] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [searchState, setSearchState] = useState(SearchState.NoSearch)
  const [searchTerms, setSearchTerms] = useQueryParam("query", StringParam)

  useEffect(() => {
    if (!siteSearchLoaded) {
      return
    }
    let host = ""
    if (window && window.location) {
      host = window.location.host
    }
    const searchPatternDev = new RegExp("^" + "dksa")
    const isDevelopmentStage = searchPatternDev.test(host)
    const searchPatternQualif = new RegExp("^" + "qksa")
    const isQualificationStage = searchPatternQualif.test(host)
    setClient(
      new window["DatoCmsSearch"](
        config.search_token,
        isDevelopmentStage ? "développement" : isQualificationStage ? "prévisualisation" : "production"
      )
    )
  }, [siteSearchLoaded])

  useEffect(() => {
    if (!client) {
      return
    }
    if (!searchTerms) {
      setSearchState(SearchState.NoSearch)
      return
    }
    setSearchState(SearchState.Searching)
    client.search(searchTerms).then(
      ({ results }) => {
        results.map(result => {
          if (window.location.hostname) {
            result.url = new URL(result.url)
            result.url.hostname = window.location.hostname
          }
        })
        setSearchResults(results)
        setSearchState(SearchState.Success)
        pushGtmEvent("eventga", "Internal Search", "Search", searchTerms)
      },
      error => {
        console.error(error)
        setSearchState(SearchState.Error)
      }
    )
  }, [client, searchTerms])

  useDetectScroll()

  function arrayEquals(a, b) {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
  }

  const [pageView, setPageView] = useState(false)
  useEffect(() => {
    if (pageView || !window) {
      return
    }
    if (arrayEquals(searchResults, [undefined])) {
      return
    }
    let host = ""
    if (window.location) {
      host = window.location.host
    }
    const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches
    const searchPattern = new RegExp("^" + "qksa")
    const isQualificationStage = searchPattern.test(host)
    pushGtmPageEvent(
      "pageview",
      config.locale,
      data.site.siteMetadata.siteUrl,
      isMobile ? "mobile" : "desktop",
      isQualificationStage ? "qualification" : "production",
      data.datoCmsSearchPage.title,
      window.location.hostname,
      null,
      "Search",
      "",
      searchResults.length || 0
    )
    setPageView(true)
  })

  return (
    <Layout
      location={location}
      menuData={data.datoCmsMenu}
      footerData={data.datoCmsFooter}
      toolbarData={data.datoCmsGlobalConfiguration}
      scriptsData={data.datoCmsGlobalConfiguration}
      locale={useFormatLocale(pageContext.locale)}
      defaultLocale={useFormatLocale(pageContext.defaultLocale)}
      relativeLanguagePaths={pageContext.relativeLanguagePaths}
      mySubscriptionsPage={data.datoCmsMySubscriptionsPage}
    >
      <Script src={siteSearchUrl} onLoad={() => setSiteSearchLoaded(true)} />
      <TitleBanner title={data.datoCmsSearchPage.title} />
      <div className="container">
        <SearchForm
          query={searchTerms}
          onChange={setSearchTerms}
          buttonLabel={data.datoCmsSearchPage.buttonLabel}
          locale={useFormatLocale(pageContext.locale)}
          defaultLocale={useFormatLocale(pageContext.defaultLocale)}
        />
        {searchState === SearchState.NoSearch && (
          <p className={styles.message}>{data.datoCmsSearchPage.noSearchLabel}</p>
        )}
        {searchState === SearchState.Searching && (
          <p className={styles.message}>{data.datoCmsSearchPage.searchInProgressLabel}</p>
        )}
        {searchState === SearchState.Error && (
          <p className={styles.message + " " + styles.error}>{data.datoCmsSearchPage.errorLabel}</p>
        )}
        {searchState === SearchState.Success && searchResults.length === 0 && (
          <p className={styles.message}>{data.datoCmsSearchPage.noResultsLabel}</p>
        )}
        {searchState === SearchState.Success &&
          searchResults.length > 0 &&
          displaySearchResults(searchTerms, searchResults)}
      </div>
    </Layout>
  )
}

function displaySearchResults(searchTerms, searchResults) {
  return (
    <dl className={styles.results}>
      {searchResults.map((searchResult, index) => (
        <div key={index}>
          <dt key={searchTerms + index + "dt"} className={styles.resultLink}>
            <a
              href={searchResult.url}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(searchResult.title),
              }}
            />
          </dt>
          <dd
            key={searchTerms + index + "dd"}
            className={styles.resultDescription}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(searchResult.body),
            }}
          />
        </div>
      ))}
    </dl>
  )
}

export const Head: React.FC<RenderProps> = ({ data }) => {
  const [searchTerms, _] = useQueryParam("query", StringParam)

  return (
    <>
      <SEO title={`${data.datoCmsSearchPage.title} ${searchTerms ? " - " + searchTerms : ""}`} />
      <meta name="viewport" content="width=device-width" />
      <LoadScripts scriptData={data.datoCmsGlobalConfiguration} />
    </>
  )
}

export const pageQuery = graphql`
  query SearchResults($locale: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    datoCmsHome(locale: $locale) {
      id
    }
    datoCmsMySubscriptionsPage {
      ...MySubscriptionsPageFields
    }
    datoCmsMenu(locale: $locale) {
      ...HeaderMenuFields
      ...FooterMenuFields
    }

    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }

    datoCmsGlobalConfiguration(locale: $locale) {
      ...HeaderToolBarFields
      ...ScriptsFields
    }

    datoCmsSearchPage(locale: $locale) {
      buttonLabel
      noSearchLabel
      title
      noResultsLabel
      errorLabel
      searchInProgressLabel
    }
  }
`

export default SearchResults
