import {
  DatoCmsDatoCmsFormUserEmailContentStructuredText,
  DatoCmsDatoCmsFormSubsidiaryEmailContentStructuredText,
} from "../../../graphql-types"
import { render as renderText } from "datocms-structured-text-to-plain-text"
import { StructuredText as StructuredTextGraphQlResponse } from "datocms-structured-text-utils/dist/types/types"

export function formToText(
  content: DatoCmsDatoCmsFormUserEmailContentStructuredText | DatoCmsDatoCmsFormSubsidiaryEmailContentStructuredText
) {
  const options = {
    renderInlineRecord({ record: block, adapter: { renderNode, renderText } }) {
      if (block.fragmentType === "Form content") {
        // return renderNode("table", { style: tableStyle }, renderFormContent(renderNode, renderText))
        return "*Form content*"
      }
      console.log("Unhandled fragment: " + block.fragmentType)
    },
  }
  const emailBody = renderText(content as unknown as StructuredTextGraphQlResponse, options)

  return emailBody
}
