import React from "react"
import { graphql } from "gatsby"
import { NotFoundPageQuery } from "../../../graphql-types"
import * as styles from "./404.module.scss"
import * as stylesTypePage from "../type-page/type-page.module.scss"
import { useFormatLocale } from "../../hooks"
import { RelativeLanguagePaths } from "../../components/layout/header/header-tool-bar/language-selector/language-selector"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Breadcrumb from "../../components/type-page/breadcrumb/breadcrumb"
import TitleBanner from "../../components/type-page/title-banner/title-banner"
import LoadScripts from "../../components/load-scripts"

type RenderProps = {
  data: NotFoundPageQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    relativeLanguagePaths: RelativeLanguagePaths
  }
}

const NotFoundPage: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  return (
    <Layout
      location={location}
      menuData={data.datoCmsMenu}
      footerData={data.datoCmsFooter}
      toolbarData={data.datoCmsGlobalConfiguration}
      scriptsData={data.datoCmsGlobalConfiguration}
      locale={useFormatLocale(pageContext.locale)}
      defaultLocale={useFormatLocale(pageContext.defaultLocale)}
      relativeLanguagePaths={pageContext.relativeLanguagePaths}
      mySubscriptionsPage={data.datoCmsMySubscriptionsPage}
    >
      <div className={stylesTypePage.typePage}>
        <TitleBanner title="ERREUR 404" />
        <Breadcrumb
          menuData={data.datoCmsMenu}
          pageTitle="404"
          locale={useFormatLocale(pageContext.locale)}
          defaultLocale={useFormatLocale(pageContext.defaultLocale)}
        />
        <div className={`${stylesTypePage.typePageContent} ${styles.wrapper}`}>
          <div className={`${stylesTypePage.content} ${styles.content}`}>
            <h1 className={styles.title}>Oups ! Cette page n'est pas disponible</h1>
            <span className={styles.subtitle}>Nous vous proposons de consulter les informations suivantes.</span>
          </div>
          <ul className={styles.linkBlocks} aria-label="Link blocks">
            {data.datoCmsNotFound.notFoundBlock.map((block, index) => {
              const internalLink = block.internalLink[0]
              return (
                <li key={index}>
                  <a href={internalLink && internalLink.__typename === "DatoCmsPage" ? internalLink.slug : "/"}>
                    <span className={styles.link}>{block.title}</span>
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data }) => {
  return (
    <>
      <SEO title="404: Page introuvable" />
      <LoadScripts scriptData={data.datoCmsGlobalConfiguration} />
    </>
  )
}

export const pageQuery = graphql`
  query notFoundPage($locale: String!) {
    site {
      siteMetadata {
        title
      }
    }
    datoCmsMySubscriptionsPage {
      ...MySubscriptionsPageFields
    }
    datoCmsMenu(locale: $locale) {
      ...HeaderMenuFields
      ...FooterMenuFields
    }

    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }

    datoCmsGlobalConfiguration(locale: $locale) {
      ...HeaderToolBarFields
      ...ScriptsFields
    }

    datoCmsNotFound {
      notFoundBlock {
        title
        internalLink {
          ... on DatoCmsPage {
            __typename
            slug
          }
          ... on DatoCmsHome {
            __typename
          }
        }
      }
    }
  }
`

export default NotFoundPage
