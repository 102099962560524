import * as config from "../../config"
import { EmailReceiversFieldsFragment } from "../../graphql-types"

export const importFiles = async (file: File): Promise<SavedFile> => {
  const headers = { Accept: "application/json", "Ocp-Apim-Subscription-Key": config.notifications_service_token }
  const formData = new FormData()
  formData.append("file", file)

  const response = await fetch(config.notifications_service_import_file_endpoint, {
    method: "POST",
    headers,
    body: formData,
  })

  if (!response.ok) {
    throw new Error(`Failed to import file "${file.name}": ${response.statusText}`)
  }
  return response.json()
}

export const formatReceivers = (receivers: EmailReceiversFieldsFragment[]) => {
  const emailJson = {
    to: "",
    cc: "",
    bcc: "",
  }

  receivers.forEach(receiver => {
    switch (receiver.sendType) {
      case "Principal":
        emailJson.to += receiver.email + ";"
        break
      case "CC":
        emailJson.cc += receiver.email + ";"
        break
      case "CCI":
        emailJson.bcc += receiver.email + ";"
        break
    }
  })

  emailJson.to = emailJson.to.slice(0, -1)
  emailJson.cc = emailJson.cc.slice(0, -1)
  emailJson.bcc = emailJson.bcc.slice(0, -1)

  return emailJson
}

/*export const sendEmail = async (
  from: string,
  receivers: EmailReceiversFieldsFragment[],
  subject: string,
  content: string,
  files: File[],
  replyTo: string
): Promise<Response> => {
  const formattedContent = content.replace(/\n/g, "<br>")
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": config.notifications_service_token,
  }
  const filesPromise = files.length > 0 ? Promise.all(files.map(importFiles)) : Promise.resolve([])
  const importedFiles = await filesPromise

  const { to, cc, bcc } = formatReceivers(receivers)

  const response = await fetch(config.notifications_service_endpoint, {
    method: "POST",
    headers,
    body: JSON.stringify({
      notificationType: "Email",
      notificationContent: {
        from,
        to,
        replyTo,
        cc,
        bcc,
        emailType: "NoTemplateWithLogoAndAttachments",
        content: {
          subject,
          htmlMessage: formattedContent,
          attachments: importedFiles,
        },
      },
    }),
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response
} */
type SendEmailV2Params = {
  trigram: string
  idForm: string
  email?: string
  fields?: { key: string; value: string }[]
  acceptTerms?: string
  header?: string
  footer?: string
  logo?: string
  primaryColor?: string
  locale?: string
  files: File[]
}
export const sendEmailV2 = async ({
  trigram,
  idForm,
  email,
  fields,
  acceptTerms,
  header,
  footer,
  logo,
  primaryColor,
  locale,
  files,
}: SendEmailV2Params): Promise<Response> => {
  const endpoint = config.serverless_sendmail_endpoint
  const ocpmSubscriptionKey = config.serverless_sendmail_token
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": ocpmSubscriptionKey,
  }

  const filesPromise = Promise.all(files.map(importFiles))
  const importedFiles = await filesPromise

  const body = JSON.stringify({
    trigram: trigram.toUpperCase(),
    idForm,
    email,
    fields,
    acceptTerms,
    header,
    footer,
    logo,
    primaryColor,
    locale,
    attachments: importedFiles,
  })

  return fetch(endpoint, {
    method: "POST",
    headers,
    body,
  }).then(response => {
    if (!response.ok) {
      throw new Error(`Erreur API: ${response.statusText}`)
    }
    return response
  })
}

// We don't want to know the type because we're not supposed to do anything with it.
// If that assumption changes, see the OAS of the service (on Keolis.Notifications repository on Azure devops)
type SavedFile = unknown
