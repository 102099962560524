const {fr} = require("date-fns/locale");
const { registerLocale, setDefaultLocale } = require("react-datepicker");

registerLocale('fr', fr);
setDefaultLocale('fr');

module.exports = {
  "api_token": "Alerte SMS",
  "date_format": {
    "fr": "dd/MM/yyyy"
  },
  "display_contact": true,
  "fb_display": true,
  "info_traffic_title": {
    "fr": "Toute l'info trafic"
  },
  "last_news_label": {
    "fr": "Actualités"
  },
  "mapbox_token": "pk.eyJ1IjoibWltaXJvc2UyOSIsImEiOiJjbGV3cHhsaGgxMHV2NDNtazAxOGM0MXhrIn0.LieDdLfJevyJ6njBc9-vQg",
  "notifications_service_endpoint": "https://apim.keolis.com/KWP000/notification",
  "search_title": {
    "fr": "Recherche"
  },
  "side_blocks": {
    "fr": [
      "42029164"
    ]
  },
  "api_url": "https://api-v2.mobireport.fr",
  "contact_label": {
    "fr": "Renseignements"
  },
  "info_traffic_slug": {
    "fr": "toutes-les-infos-trafic"
  },
  "news_redirect_label": {
    "fr": "Lire l'actualité"
  },
  "notifications_service_import_file_endpoint": "https://apim.keolis.com/KWP000/file/import",
  "search_button_label": {
    "fr": "Rechercher"
  },
  "serverless_sendmail_endpoint": "https://apim.keolis.com/dato/send/form",
  "time_format": {
    "fr": "HH:mm"
  },
  "twitter_display": false,
  "website_name": "Zestbus",
  "contact_phone_number": {
    "fr": "+33 4 93 35 93 60 "
  },
  "info_traffic_detail_slug": {
    "fr": "Détail de l'info trafic"
  },
  "no_search_label": {
    "fr": "Veuillez saisir des termes et valider pour lancer la recherche"
  },
  "notifications_service_token": "1142321004f3407b98a95856f9820607",
  "primary_color": {
    "red": 32,
    "blue": 155,
    "alpha": 255,
    "green": 83
  },
  "serverless_sendmail_token": "1142321004f3407b98a95856f9820607",
  "alt_text": {
    "fr": "Renseignements : +33 4 93 35 93 60 "
  },
  "gradient_primary_color": {
    "red": 53,
    "blue": 239,
    "alpha": 255,
    "green": 130
  },
  "info_traffic_content_title": {
    "fr": "L'information trafic"
  },
  "search_in_progress_label": {
    "fr": "Recherche en cours..."
  },
  "gradient_button_color": {
    "red": 48,
    "blue": 155,
    "alpha": 255,
    "green": 146
  },
  "redirection_button_text": {
    "fr": "+ D'INFOS"
  },
  "search_no_results_label": {
    "fr": "Il n'y a aucun résultat pour cette recherche"
  },
  "active_button_text_color": {
    "red": 255,
    "blue": 252,
    "alpha": 255,
    "green": 252
  },
  "search_error_label": {
    "fr": "Une erreur s'est produite, veuillez réessayer dans quelques minutes"
  },
  "search_token": "61c1a3f42699b05e031a8d6a0094bd",
  "secondary_color": {
    "red": 253,
    "blue": 251,
    "alpha": 255,
    "green": 253
  },
  "subsidiary_logo": {
    "path": "/53518/1718981746-logo-zest-blanc-1.png",
    "format": "png",
    "size": 60001,
    "alt": null,
    "title": "Logo Zest blanc",
    "width": 2699,
    "height": 1128
  },
  "back_to_home_title": {
    "fr": "Zestbus – revenir à la page d'accueil"
  },
  "language_selector_title": {
    "fr": "Changer la langue"
  },
  "piano_site_id": "639210",
  "google_tag_manager_id": "GTM-NL3VZ8L",
  "google_analytics_key": "",
  "didomi_api_key": "2127e597-3d3f-4aa2-9727-c8126f86298c",
  "actistat_key": "",
  "loader": {
    "path": "/53518/1693505770-loader-zestbus.gif",
    "format": "gif",
    "size": 644937,
    "alt": "Page en chargement, veuillez patientez s'il vous plaît",
    "title": "Page en chargement",
    "width": 1080,
    "height": 1080
  },
  "api_url_get_lines_endpoint": "/public/line",
  "api_url_user_registration_endpoint": "/public/customer",
  "scripts": [],
  "form_captcha_key": "6Lcd1DMjAAAAAEsHVSyCqBSA_c8IBpjJStjgY_pH",
  "updated_at": "2024-10-14T14:04:39.541+02:00",
  "created_at": "2020-12-29T15:46:21.809+01:00",
  "locale": "fr",
  "locales": [
    "fr"
  ],
  "siteUrl": "https://www.zestbus.fr/",
  "readOnlyApi": "1bcfd9f97df166831525de05c4caa1"
}