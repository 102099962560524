import React from "react"
import { RegisterOptions, UseFormRegisterReturn } from "react-hook-form"

type RenderProps = {
  id?: string
  type: string
  name: string
  required?: boolean
  className?: string
  placeholder?: string
  accept?: string
  onChange: (value: FileList) => void
  register?: (name: string, options?: RegisterOptions) => UseFormRegisterReturn
  onFocus?: (value: any) => void
}

const FileInput: React.FC<RenderProps> = ({
  id,
  type,
  name,
  required,
  className = "",
  placeholder = "",
  accept,
  onChange,
  register,
  onFocus,
}) => {
  return (
    <input
      id={id}
      {...register(name, { required })}
      type="file"
      name={name}
      placeholder={placeholder}
      required={required}
      onChange={event => onChange(event.target.files)}
      className={className}
      onFocus={event => {
        onFocus && onFocus(event.target.id)
      }}
      accept={accept}
    />
  )
}

export default FileInput
