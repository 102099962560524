export const setLocalStorage = (key: string, value: unknown): void => {
  try {
    if (typeof window === "undefined") return
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.error(`Error saving to localStorage: ${error}`)
  }
}

export const getLocalStorage = <T>(key: string): T | null => {
  try {
    if (typeof window === "undefined") return null
    const item = window.localStorage.getItem(key)
    console.log("item", item)
    return item ? (JSON.parse(item) as T) : null
  } catch (error) {
    console.error(`Error reading from localStorage: ${error}`)
    return null
  }
}
