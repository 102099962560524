import React, { useRef } from "react"
import * as styles from "./popup.module.scss"
import * as inputStyles from "../layout/inputs.module.scss"
import { ReactSVG } from "react-svg"

type Button = {
  label: string
  action: () => void
}
type RenderProps = {
  title: string
  message: string
  overlay?: boolean
  icon?: { src: string; alt: string; style?: any }
  dismiss?: {
    message: string
    action: () => void
  }
  button1?: Button
  button2?: Button
}

const Popup = ({ title, message, icon, overlay, dismiss, button1, button2 }: RenderProps) => {
  const dismissCheckboxRef = useRef<HTMLInputElement>(null)
  const onButton1Click = () => {
    if (dismiss && dismissCheckboxRef.current?.checked) {
      dismiss.action()
    }
    button1.action()
  }
  return (
    <>
      {overlay && <div className={`${styles.overlay}`}></div>}
      <div className={styles.popup}>
        <div className={styles.content}>
          {icon && (
            <ReactSVG
              src={icon.src}
              className={icon.style}
              beforeInjection={svg => svg.setAttribute("aria-label", icon.alt || "")}
            />
          )}
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.message} dangerouslySetInnerHTML={{ __html: message }} />
          <div>
            {button1 && (
              <button className={inputStyles.primaryButton} onClick={onButton1Click} autoFocus>
                {button1.label}
              </button>
            )}
            {button2 && <button onClick={button2.action}>{button2.label}</button>}
          </div>
          {dismiss.message && (
            <div className={styles.dismissMessageContainer}>
              <label className={styles.dismissMessage} htmlFor="dismissCheckbox">
                <input
                  type="checkbox"
                  id="dismissCheckbox"
                  ref={dismissCheckboxRef}
                  className={styles.dismissCheckbox}
                />
                {dismiss.message}
              </label>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Popup
