export const detectWebView = () => {
  if (typeof window === "undefined") {
    return false // server-side
  }
  const userAgent = window.navigator.userAgent
  const standalone = window.matchMedia("(display-mode: standalone)").matches

  // WebViews Android
  const isAndroidWebView = /Version\/[\d.]+.*Chrome\/[\d.]+ Mobile Safari\/[\d.]+/.test(userAgent)

  // WebViews iOS
  const isIOSWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/.test(userAgent)

  return isAndroidWebView || isIOSWebView || standalone
}
