import React, { useEffect, useState } from "react"
import { useQuerySubscription } from "react-datocms"
import { graphql, useStaticQuery } from "gatsby"
import { LinesListFieldsFragment } from "../../../graphql-types"
import * as config from "../../../config.js"
import * as styles from "./info-traffic.module.scss"
import sanitizeHtml from "sanitize-html"
import { useIsToday } from "../../hooks"
import { ReactSVG } from "react-svg"
import bell from "../../../static/images/bell.svg"

import { useLinesSubscription } from "../../hooks/use-lines-subscription"
import { getLocalStorage, setLocalStorage } from "../../utils/accessLocalStorage"

import Popup from "../popup/popup"

const InfoTraffic: React.FC = () => {
  const globalConfig = useStaticQuery(graphql`
    query MyQuery {
      datoCmsGlobalConfiguration {
        id
        infoTrafficContentTitle
      }
      allDatoCmsPage(filter: { isInfoTrafficDetailPage: { eq: true } }) {
        nodes {
          id
          title
          slug
          isInfoTrafficDetailPage
        }
      }
    }
  `)

  const [infoTrafficDetailPage, setInfoTrafficDetailPage] = useState(null)
  useEffect(() => {
    if (!infoTrafficDetailPage) {
      if (globalConfig.allDatoCmsPage?.nodes) {
        let infoLocalized = globalConfig.allDatoCmsPage.nodes[0]
        setInfoTrafficDetailPage(infoLocalized)
      }
    }
  }, [infoTrafficDetailPage])

  const { toggleSubscription, isSubscribed, isMySubscriptionsPageEnabled, mySubscriptionsPage } = useLinesSubscription()
  const [info, setInfo] = useState(null)
  const [dataInfo, setDataInfo] = useState(null)
  const [showPopup, setShowPopup] = useState(false)
  const [popupInfos, setPopupInfos] = useState({
    popupTitle: "",
    popupMessage: "",
    popupIconStyle: "",
  })

  useEffect(() => {
    if (dataInfo) {
      const parsedInfo = parseInfoTraffic(dataInfo.allInfoTraffics)
      setInfo(parsedInfo)
    }
  }, [dataInfo])

  const response = useQuerySubscription({
    enabled: true,
    query: `
      query infoQuery {
        allInfoTraffics(locale: ${config.locale}, orderBy: [start_DESC], first: 100) {
          start
          end
          id
          title
          disruptionType
          displayInHome
          pictogram {
            url
            alt
            format
          }
          lines {
            id
            number
            name
            pictogram {
              url
              alt
              format
            }
          }
          shortDescription
          slug
        }
      }`,
    token: config.readOnlyApi,
  })

  if (response.data) {
    if (response.data != dataInfo) {
      setDataInfo(response.data)
    }
  }

  function parseInfoTraffic(dataToParse) {
    const lines = []
    dataToParse.forEach(infoTraffic => {
      infoTraffic.lines.map(line => {
        lines.push(line)
      })
    })

    const uniqueLines = lines.filter((ele, index) => index === lines.findIndex(elem => elem.id === ele.id))
    const myLinesWithTraffic = []
    uniqueLines.forEach(line => {
      const infoTrafficArray = []
      dataToParse.forEach(infoTraffic => {
        infoTraffic.lines.forEach(subline => {
          if (line.name == subline.name && useIsToday(infoTraffic.start, infoTraffic.end)) {
            infoTrafficArray.push(infoTraffic)
            myLinesWithTraffic.push({ line: line, traffic: infoTrafficArray })
          }
        })
      })
    })

    return myLinesWithTraffic.filter((ele, index) => {
      return index === myLinesWithTraffic.findIndex(elem => elem.line === ele.line)
    })
  }

  const handleSubscription = (line: LinesListFieldsFragment) => {
    toggleSubscription(line.id)
    if (isSubscribed(line.id)) {
      setPopupInfos({
        popupTitle: mySubscriptionsPage.popupUnsubscriptionTitle,
        popupMessage: `${mySubscriptionsPage.popupUnsubscriptionMessage} <span>${line.name}</span>`,
        popupIconStyle: styles.bellProps,
      })
    } else {
      setPopupInfos({
        popupTitle: mySubscriptionsPage.popupSubscriptionTitle,
        popupMessage: `${mySubscriptionsPage.popupSubscriptionMessage} <span>${line.name}</span>`,
        popupIconStyle: `${styles.bellFull} ${styles.bellProps}`,
      })
    }
    setShowPopup(!showPopup)
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{globalConfig.datoCmsGlobalConfiguration.infoTrafficContentTitle}</h3>
      {showPopup && isMySubscriptionsPageEnabled && !getLocalStorage("hidePopup") && (
        <Popup
          title={popupInfos.popupTitle}
          message={popupInfos.popupMessage}
          overlay={true}
          icon={{ src: bell, alt: "subscription bell", style: popupInfos.popupIconStyle }}
          dismiss={{
            message: mySubscriptionsPage.popupCheckboxLabel,
            action: () => setLocalStorage("hidePopup", true),
          }}
          button1={{ label: "OK", action: () => setShowPopup(!showPopup) }}
        />
      )}
      {info?.map(line => {
        return (
          <div className={styles.line} key={line.line.id}>
            <div className={styles.lineHeader}>
              <h4 className={line.traffic.length > 1 ? styles.pictoBoxTop : styles.pictoBox}>
                <img src={line.line.pictogram.url} alt={line.line.pictogram.alt} className={styles.svgImage} />
              </h4>
              {isMySubscriptionsPageEnabled && (
                <button className={styles.bellButton}>
                  <ReactSVG
                    src={bell}
                    className={`${styles.bell} ${isSubscribed(line.line.id) ? styles.bellFull : ""}`}
                    onClick={() => handleSubscription(line.line)}
                  />
                </button>
              )}
            </div>

            <ul className={styles.lineDivider}>
              {line.traffic.map(traffic => {
                return (
                  <li className={styles.trafficContainer} key={traffic.id}>
                    <a
                      href={
                        `/${infoTrafficDetailPage.slug}` +
                        "?" +
                        // traffic.title.replace(new RegExp(" ", "g"), "-") +
                        traffic.title.replace(/ /g, "-") +
                        "#" +
                        traffic.id
                      }
                      className={styles.linkNoStyle}
                    >
                      <div className={styles.pictoBoxTraffic}>
                        <img
                          src={traffic.pictogram.url}
                          alt={traffic.pictogram.alt}
                          className={styles.svgImage}
                          aria-hidden="true"
                        />
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(traffic.shortDescription),
                        }}
                      />
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export default InfoTraffic
