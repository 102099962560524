import {
  DatoCmsDatoCmsLostObjectsFormConfigurationSubsidiaryEmailContentStructuredText,
  DatoCmsDatoCmsLostObjectsFormConfigurationUserEmailContentStructuredText,
} from "../../../graphql-types"
import { render as renderText } from "datocms-structured-text-to-plain-text"
import { StructuredText as StructuredTextGraphQlResponse } from "datocms-structured-text-utils/dist/types/types"

export function lostObjectsFormToText(
  content:
    | DatoCmsDatoCmsLostObjectsFormConfigurationUserEmailContentStructuredText
    | DatoCmsDatoCmsLostObjectsFormConfigurationSubsidiaryEmailContentStructuredText
) {
  const options = {
    renderInlineRecord({ record: block, adapter: { renderNode, renderText } }) {
      if (block.fragmentType === "Form content") {
        // return renderNode("table", {}, renderFormContent(renderNode, renderText))
        return "*Form content*"
      } else {
        console.log("Unhandled fragment: " + block.fragmentType)
      }
    },
  }
  return renderText(content as unknown as StructuredTextGraphQlResponse, options)
}
